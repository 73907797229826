import React from 'react';
import { AiFillEye } from 'react-icons/ai';
import { GrSchedules } from 'react-icons/gr';

const Data = [
	{
		id: 1,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		title:
			'¡Como conseguir tu primer empleo como programador!',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 2,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title:
			'¡Son importantes las comunidades de desarrollo de software, ¿Por qué?!',
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 3,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		title: 'Que es Git y Github? Te ayudan?',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 4,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title: '¡De Ingeniero Mecánico  a Frontend Developer!',
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
		Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
		La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 5,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title: `¡Por qué JavaScript es una muy buena opcion para iniciate
			en el desarrollo web!`,
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 6,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title: 'React vs Angular vs Vue!',
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 7,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title: 'React vs Angular vs Vue!',
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 8,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title: 'React vs Angular vs Vue!',
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 9,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title: 'React vs Angular vs Vue!',
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
	{
		id: 10,
		eyeIcon: <AiFillEye size='13' />,
		scheduleIcon: <GrSchedules size='30' />,
		date: new Date(2019, 0, 1),
		topic: 'Personal',
		intro: `Basado en mi experiencia y resumido en 10 puntos, te
    explico lo que necesitas saber para conseguir tu
    primer empleo como programador.`,
		title: 'React vs Angular vs Vue!',
		text: `Ya decidiste el tipo de desarrollador que serás, ahora debes saber que requieres saber o conocer para conseguir el empleo deseado.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.
Según el país donde vivas existen portales en Internet donde empleadores publican las ofertas de trabajo disponibles. Realiza una búsqueda, puedes probar por ejemplo con “Desarrollador backend jr”. No te apresures a mandar a postularte a cuanto trabajo aparezca en tu búsqueda.	Lo que debes hacer es analizar el mercado.
La mayoría de las ofertas de trabajo te solicitarán tecnologías que deberás conocer y las dividen en dos secciones, Requeridas las cuales debes de conocer obligatoriamente para conseguir el trabajo y las deseadas que son un plus o extra si las conoces.`,
	},
];

export default Data;
